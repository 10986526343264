<template>
  <div class="detail">
    <div class="detail-title">
      <Title :title="$t('block.countdown')" />
    </div>
    <div class="countdown-container detail-content">
      <div class="image-container">
        <img class="current_promoted" :src="current_promoted" />
      </div>
      <div
        v-bind:style="currentBlockNumber !== '' ? '' : 'display:none'"
        class="countdown-panel"
      >
        <div class="times">
          <div class="element">
            <span class="time">{{ remainingDays }}</span>
            <span class="text">{{ $t("blockcountdown.days") }}</span>
          </div>
          <div class="element">
            <span class="time">{{ remainingHours }}</span>
            <span class="text">{{ $t("blockcountdown.hours") }}</span>
          </div>
          <div class="element">
            <span class="time">{{ remainingMinutes }}</span>
            <span class="text">{{ $t("blockcountdown.minutes") }}</span>
          </div>
          <div class="element">
            <span class="time">{{ remainingSeconds }}</span>
            <span class="text">{{ $t("blockcountdown.seconds") }}</span>
          </div>
        </div>
        <div class="estimated">
          <div class="element">
            <div class="text">{{ $t("blockcountdown.estimateddate") }}</div>
            <div class="number">
              {{ `${targetDate}` }}
            </div>
          </div>
        </div>
        <div class="blocks">
          <div class="element">
            <div class="text">{{ $t("blockcountdown.targetblock") }}</div>
            <div class="number">{{ `#${targetBlockNumber}` }}</div>
          </div>
          <div class="element">
            <div class="text">{{ $t("blockcountdown.currentblock") }}</div>
            <div class="number">{{ `#${currentBlockNumber}` }}</div>
          </div>
          <div class="element">
            <div class="text">{{ $t("blockcountdown.remainingblocks") }}</div>
            <div class="number">
              {{ `#${blockDifference}` }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getChain } from "@/api/_common";
import Title from "@/components/SecondaryTitle.vue";
import current_promoted from "@/assets/image/elf.jpeg";
export default {
  components: {
    Title,
  },
  data() {
    return {
      targetBlockNumber: "",
      currentBlockNumber: "",
      blockTime: "",
      current_promoted,
      timeDifference: 0,
      targetDate: "",
    };
  },
  mounted() {
    this.init();
    this.timer = setInterval(() => {
      if (this.timeDifference > 0) {
        this.timeDifference = this.timeDifference - 1;
        localStorage.setItem("timeDifference", this.timeDifference - 1);
      } else if (this.timeDifference === 1) {
        this.timeDifference = this.timeDifference - 1;
        localStorage.clear("timeDifference");
        clearInterval(this.timer);
      }
    }, 1000);
    this.blockTimer = setInterval(() => {
      this.getCurrentBlockNumber();
    }, 5000);
  },
  beforeUnmount() {
    clearInterval(this.timer);
    clearInterval(this.blockTimer);
  },
  computed: {
    blockDifference() {
      return this.targetBlockNumber - this.currentBlockNumber;
    },
    remainingDays() {
      return Math.floor(this.timeDifference / (3600 * 24));
    },
    remainingHours() {
      return Math.floor((this.timeDifference % (3600 * 24)) / 3600);
    },
    remainingMinutes() {
      return Math.floor((this.timeDifference % 3600) / 60);
    },
    remainingSeconds() {
      return Math.floor(this.timeDifference % 60);
    },
  },
  methods: {
    async init() {
      this.targetBlockNumber = parseInt(this.$route.params.Number);
      let res = await getChain();
      this.currentBlockNumber = parseInt(res.Height);
      if (
        localStorage.getItem("timeDifference") == null ||
        (localStorage.getItem("timeDifference") != null &&
          localStorage.getItem("targetBlockNumber") !=
            parseInt(this.$route.params.Number))
      ) {
        this.blockTime = parseFloat(res.BlockTime);
        this.timeDifference = parseInt(this.blockDifference * this.blockTime);
        this.targetDate = new Date(Date.now() + this.timeDifference * 1000);
        localStorage.setItem("timeDifference", this.timeDifference);
        localStorage.setItem("targetBlockNumber", this.targetBlockNumber);
        localStorage.setItem("targetDate", this.targetDate);
      } else {
        this.timeDifference = localStorage.getItem("timeDifference");
        this.targetDate = localStorage.getItem("targetDate");
      }
    },
    async getCurrentBlockNumber() {
      let res = await getChain();
      this.currentBlockNumber = parseInt(res.Height);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/detail.scss";
.countdown-container {
  flex-direction: row;
  display: grid;
  grid-template-columns: auto auto;
  @media screen and (max-width: $breakpoint-xl) {
    column-gap: 2vw;
  }
  @media screen and (max-width: $breakpoint-sm) {
    grid-template-columns: auto;
  }
  .image-container {
    display: flex;
    flex-direction: column;
    .current_promoted {
      width: 300px;
      height: auto;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .countdown-panel {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    justify-content: center;
    @media screen and (max-width: $breakpoint-sm) {
      margin-top: 20px;
    }
    .times {
      display: grid;
      grid-template-columns: auto auto auto auto;
      column-gap: 1.5em;
      row-gap: 1em;
      .element {
        text-align: left;
        display: flex;
        column-gap: 7px;
        align-items: baseline;
      }
      @media screen and (max-width: $breakpoint-xl) {
        grid-template-columns: auto;
      }
      .time {
        color: #3498db;
        font-size: 45px;
        line-height: 45px;
        font-weight: bold;
        @media screen and (max-width: $breakpoint-xl) {
          font-size: 7vw;
          line-height: 7vw;
        }
        @media screen and (max-width: $breakpoint-sm) {
          font-size: 10vw;
          line-height: 10vw;
        }
      }
      .text {
        font-size: 20px;
        @media screen and (max-width: $breakpoint-xl) {
          font-size: 3vw;
        }
        @media screen and (max-width: $breakpoint-sm) {
          font-size: 5vw;
        }
      }
    }
    .estimated {
      display: flex;
      flex-direction: row;
      padding-left: 5px;
      @media screen and (max-width: $breakpoint-sm) {
        padding: 20px 40px 20px 20px;
      }
      .text {
        text-align: start;
        @media screen and (max-width: $breakpoint-sm) {
          text-align: center;
        }
      }
      .number {
        padding: 10px 0 0 0;
        font-weight: bolder;
        color: #4a4f55;
        text-align: start;
        @media screen and (max-width: $breakpoint-sm) {
          text-align: center;
        }
      }
    }
    .blocks {
      display: grid;
      flex-direction: row;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 1em;
      row-gap: 1em;
      @media screen and (max-width: $breakpoint-sm) {
        grid-template-columns: 1fr 1fr;
      }
      .element {
        background: #3498db;
        padding: 20px;
        border-radius: 5px;
        text-align: left;
        opacity: 0.8;
        line-height: 1.1;
        @media screen and (max-width: $breakpoint-xl) {
          padding: 10%;
        }
        .text {
          color: white;
        }
        .number {
          padding: 5px 0 0 0;
          text-align: start;
          color: white;
          font-size: 1.2em;
        }
      }
      .element:nth-child(2) {
        background: #008673;
      }
      .element:nth-child(3) {
        background: #623a8b;
      }
    }
  }
}
</style>
